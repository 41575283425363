import { inject, Injectable } from "@angular/core";
import { isAndroidApp } from "@limblecmms/lim-ui";
import Cookies from "js-cookie";
import { ManageLang } from "src/app/languages/services/manageLang";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { RoutingSetupService } from "src/app/shared/services/routing/routing-setup.service";

@Injectable({ providedIn: "root" })
export class AppRootService {
   private readonly manageLang = inject(ManageLang);
   private readonly routingSetupService = inject(RoutingSetupService);
   private readonly launchFlagsService = inject(LaunchFlagsService);

   public constructor() {
      /**
       * Since translated phrases are sometimes stored in local variables as non-reactive strings,
       * we need to trigger loading the phrases on app startup to reduce the chance of the race condition
       * of the asynchronous phrase loading causing the phrase to be a default empty string.
       */
      inject(TranslationService);
   }

   public appStartup() {
      this.launchFlagsService.initialize();
      this.routingSetupService.runSetup();
      this.manageLang.calcLang(undefined);
      this.setMediaPermissionOnMobile();
   }

   public animationShouldPlay() {
      if (Cookies.get("UID") === undefined) {
         return true;
      }
      return false;
   }

   private async setMediaPermissionOnMobile() {
      // goNative removed the initial permissions check to allow access to camera.  This navigator call triggers it
      // so that customers can still use camera on Android when trying to add a picture.
      if (isAndroidApp()) {
         // Checking Camera Permissions

         const stream = await navigator.mediaDevices.getUserMedia({ video: true });

         const videoTrack = stream.getVideoTracks()[0];
         videoTrack.enabled = false;
         videoTrack.stop();
      }
   }
}
